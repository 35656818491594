import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function Writings() {
  const [articles, setArticles] = useState([]);
  const [selectedTag, setSelectedTag] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 6;

  useEffect(() => {
    fetch('/db.json')
      .then((response) => response.json())
      .then((data) => setArticles(data.articles))
      .catch((error) => console.error('Error fetching articles:', error));
  }, []);

  const handleTagClick = (tag) => {
    setSelectedTag(tag);
    setCurrentPage(1); // Reset to the first page when the tag is changed
  };

  const filteredArticles = selectedTag === 'All'
    ? articles
    : articles.filter(article => article.tags.includes(selectedTag));

  // Calculate the indices of the first and last articles on the current page
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);

  // Determine the number of pages needed
  const totalPages = Math.ceil(filteredArticles.length / articlesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="about">
      <h1 className="about-title">Writings</h1>
      <h4 className="heading-tags">Tags</h4>

      <div className="tag-stuff">
        <button className={`button-tags w-button ${selectedTag === 'All' ? 'active' : ''}`} onClick={() => handleTagClick('All')}>All</button>
        <button className={`button-tags w-button ${selectedTag === 'Techs' ? 'active' : ''}`} onClick={() => handleTagClick('Techs')}>Techs</button>
        <button className={`button-tags w-button ${selectedTag === 'MEV' ? 'active' : ''}`} onClick={() => handleTagClick('MEV')}>MEV</button>
        <button className={`button-tags w-button ${selectedTag === 'Web3' ? 'active' : ''}`} onClick={() => handleTagClick('Web3')}>Web3</button>
        <button className={`button-tags w-button ${selectedTag === 'Others' ? 'active' : ''}`} onClick={() => handleTagClick('Others')}>Others</button>
      </div>

      {currentArticles.length > 0 ? (
        <>
          <div className="article-container">
            {currentArticles.map((article) => (
              <Link to={`/artical/${article.id}`} className="artical-card" key={article.id}>
                <img src={article.image} loading="lazy" width="166" alt="" className="image-articals" />
                <div className="div-detail">
                  <h4 className="article-title">{article.title}</h4>
                  <div className="text-block-artical">{article.date} by {article.author}</div>
                  <div className="detail-stuff-artical">{article.summary}</div>
                </div>
              </Link>
            ))}
          </div>

          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                className={`pagination-button ${currentPage === index + 1 ? 'active-page' : ''}`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      ) : (
        <p>No articles available.</p>
      )}

    </div>
  );
}

export default Writings;

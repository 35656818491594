import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

function Artical() {
  const { id } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    fetch('/db.json')
      .then((response) => response.json())
      .then((data) => {
        const foundArticle = data.articles.find((article) => article.id === id);
        setArticle(foundArticle);
      })
      .catch((error) => console.error('Error fetching article:', error));
  }, [id]);

  if (!article) {
    return <div>Loading...</div>;
  }

  return (
    <div className="about">
      <Link to="/writings" className="go-back">← Go back</Link>

      <h1 className="about-title artical-title">{article.title}</h1>

      <h4 className="heading-tags timesanddetail">{article.date} | {article.tags.join(', ')} | {article.author}</h4>

      <img src={article.image} loading="lazy" sizes="(max-width: 991px) 90vw, 40vw" alt="" className="artical-image-detail" />

      {article.details.map((detail, index) => (
        <div key={index}>
          <br /><br />
          <span className="text-span-2">
            <strong className="title-artical">{detail.sectionTitle}</strong>
          </span>
          <br /><br />
          <p className="paragraph-artical">{detail.content}</p>
          <br /><br />
        </div>
      ))}
    </div>
  );
}

export default Artical;
import one from './images/image-1.png';
import two from './images/image-2.png';
import thr from './images/image-3.png';
import fou from './images/image-4.png';
import fiv from './images/image-5.png';
import six from './images/image-6.png';
import sev from './images/image-7.png';
import eig from './images/image-8.png';

function Projects() {
  return (
      <div className="about">
        <h1 className="about-title">Projects</h1>
        <p className="paragraph">Since Feburuary 2021, I&#x27;ve developed a comprehensive portfolio of projects, most of which remain private due to their potential for revenue generation. These projects showcase my capabilities and can be adapted to meet the specific needs of clients who engage my services. I leverage this portfolio as a reference point during client consultations to demonstrate how my expertise can address their unique requirements.</p>
        <div className="div-table-contents">
          <p className="paragraph-contents">Fullstack<br />•Oth3llo<br />•TORMO<br />•721 Ether Plants<br />•Neo Tokyo Punks<br />•Airrace X<br />‍<br />Solidity<br />•ERC20 launchpad<br />•ERC721 launchpad<br />•ERC1155 launchpad<br />•NFT &amp; ERC20 airdropper<br />•NFT Marketplace<br />•NFT peer to peer exchange<br />•NFT Staking &amp; Yield ERC20 as reward<br /><br />Others<br />•NFT Minting Bot<br />•Metadata uploadpad<br />•Merkle tree generator<br /></p>
        </div>
        <h3 className="career-time projects">Clients</h3>
        <p className="paragraph detail">Here are some clients used my code.</p>
        <div className="clients-images">
          <img src={one} alt="Sponsor 1" />
          <img src={two} alt="Sponsor 2" />
          <img src={thr} alt="Sponsor 3" />
          <img src={fou} alt="Sponsor 4" />
          <img src={fiv} alt="Sponsor 5" />
          <img src={six} alt="Sponsor 6" />
          <img src={sev} alt="Sponsor 7" />
          <img src={eig} alt="Sponsor 8" />
        </div>
        <h3 className="career-time projects">Fullstack</h3>
        <h4 className="career-time detail work"><strong>TORMO</strong></h4>
        <p className="paragraph detail">•Build time : 4 months<br />•Contract(s) : 3<br />•Tested : True<br />•Repo(s) : Private<br /><br />TORMO is a product developed by Passkeys, designed specifically for 株式会社PBADAO. It utilizes ERC4337 account abstraction to streamline the process of creating a crypto wallet for users. With Passkeys, users can seamlessly generate a crypto wallet without the need to manage any private keys, thanks to the innovative features of ERC4337.<br /></p>
        <h4 className="career-time detail work"><strong>721 Ether Plants</strong></h4>
        <p className="paragraph detail">•Build time : 1 months<br />•Contract(s) : 3<br />•Tested : True<br />•Repo(s) : Private<br /><br />721 Ether Plants is a NFT collection of full on-chain NFTs planted in the ether. The plants can be watered (send tx by NFT owner) once a week and will grow each time they are watered. They'll become mature after being watered 10 times. This is a experimental project playing with timestamp manipulation on ethereum chain.<br /></p>
        <h4 className="career-time detail work"><strong>Neo Tokyo Punks</strong></h4>
        <p className="paragraph detail">•Build time : 3 months<br />•Contract(s) : 1<br />•Tested : True<br />•Repo(s) : Private<br /><br />I played a pivotal role in the Neo Tokyo Punks NFT project, particularly in developing the NFT smart contracts, minting dapps and contribution of project deliver. One of the standout features of these contracts was the implementation of a merkle tree whitelist, which was likely the first of its kind to be utilized in a Japanese NFT project for whitelist sales.<br /></p>
        <h3 className="career-time projects">Solidity</h3>
        <h4 className="career-time detail work"><strong>ERC20 launchpad</strong></h4>
        <p className="paragraph detail">•Build time : 5 hours<br />•Contract(s) : 1<br />•Tested : True<br />•Repo(s) : Private<br /><br />The ERC20 launchpad I developed is tailored for individuals seeking to create customized ERC20 tokens quickly and effortlessly. Users have the flexibility to specify parameters such as maximum supply, minimum supply required to hold, as well as the token&#x27;s name and symbol. This streamlined process empowers users to generate their ERC20 tokens with ease, enabling them to bring their unique projects to life in the crypto space.</p>
        <h4 className="career-time detail work"><strong>NFT &amp; ERC20 airdropper</strong></h4>
        <p className="paragraph detail">•Build time : 1 hour<br />•Contract(s) : 1<br />•Tested : True<br />•Repo(s) : Private<br /><br />A simple smart contract that enables NFT creators to create an airdrop contract that allows anyone that owns token ids.</p>
        <h4 className="career-time detail work"><strong>NFT Marketplace</strong></h4>
        <p className="paragraph detail">•Build time : 5 days<br />•Contract(s) : 1<br />•Tested : False<br />•Repo(s) : Private<br /><br />Sell and buy bundles of NFTs, sell or buy instantly on the floor prices.</p>
        <h4 className="career-time detail work"><strong>NFT peer to peer exchange</strong></h4>
        <p className="paragraph detail">•Build time : 10 days<br />•Contract(s) : 1<br />•Tested : False<br />•Repo(s) : Private<br /><br />A peer-to-peer exchange for ERC721 NFTs where users can list their NFTs and specify which NFTs they want to trade for.</p>
        <h4 className="career-time detail work"><strong>NFT Staking &amp; Yield ERC20 as reward</strong></h4>
        <p className="paragraph detail">•Build time : 3 days<br />•Contract(s) : 2<br />•Tested : True<br />•Repo(s) : Private<br /><br />A platform where users can stake their NFTs and earn custom ERC20 tokens as rewards.</p>
        <h3 className="career-time projects">Others</h3>
        <h4 className="career-time detail work"><strong>NFT Minting Bot</strong></h4>
        <p className="paragraph detail">•Build time : 5 hours<br />•Repo(s) : Private<br /><br />This system is designed to generate over 100 new public and private key pairs to overcome address limitations for minting on certain contracts.</p>
        <h4 className="career-time detail work"><strong>Metadata uploadpad</strong></h4>
        <p className="paragraph detail">•Build time : 3 hours<br />•Repo(s) : Private<br /><br />A simple app where users can upload images and other metadata for ERC721 or ERC1155 tokens to IPFS, or choose another server if they prefer.</p>
        <h4 className="career-time detail work"><strong>Merkle Tree generator</strong></h4>
        <p className="paragraph detail">•Build time : 5 days<br />•Contract(s) : 1<br />•Tested : True<br />•Repo(s) : Private<br /><br />A practical generator that creates a merkle tree, supporting multiple data types for the initial hash, such as addresses for a whitelist. It uses efficient keccak256 hashing, similar to openzeppelin’s MerkleProof.sol, and can be used for any whitelist or restrictive blockchain action. Just import your JSON file of values, and it will produce a JSON output file for easy frontend integration.</p>
      </div>
  );
}

export default Projects;

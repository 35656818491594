function About() {
  return (
      <div className="about">
        <h1 className="about-title">Welcome</h1>
        <p className="paragraph">0xSumo is a 21 y/o anon dev, straddling the realms between the digital ether and the tangible world. His journey in technology is fueled by a boundless ambition and an curiosity for exploring new field within the tech landscape.<br /><br />In the heart of the ever-evolving digital age, his playground is the innovative and dynamic field of web3 — a space where he blends imagination with code to create decentralized applications that redefine interactions on the web. With a foundation in Ethereum Virtual Machine (EVM) compatible blockchains, his toolkit includes Solidity for smart contracts, JavaScript for dapp development, and a sprinkle of Rust to add that extra flair to my projects.<br /><br />As he navigates through the complexities of blockchain technology, he finds himself constantly drawn to the endless possibilities it presents. From creating decentralised finance (DeFi) solutions to exploring the vast universe of non-fungible tokens (NFTs), his work aims to bridge the gap between the ethereal world of digital assets and the practical needs of users in the real world.</p>
      </div>
  );
}

export default About;

function Contacts() {
  return (
      <div className="about">
        <h1 className="about-title">Contacts</h1>
        <p className="paragraph detail enqueries">Email</p>
        <h3 className="heading-2">0xSumo@proton.me</h3>
        <p className="paragraph detail enqueries">Discord</p>
        <h3 className="heading-2">0xSumo</h3>
      </div>
  );
}

export default Contacts;

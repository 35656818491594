import React from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import logo from './images/0xsumo.png';
import About from './About';
import Career from './Career';
import Projects from './Projects';
import Writings from './Writings';
import Contacts from './Contacts';
import Artical from './Artical';

function App() {
  return (
    <Router>
      <div className="body">
        <div className="div-home">
          <div className="div-block">
            <div className="header">
              <img src={logo} loading="lazy" width="40" sizes="40px" alt="0xSumo Logo" />
              <h1 className="heading">0xSumo</h1>
            </div>
            <div className="div-line"></div>
            <div id="w-node-_9c57a17f-0db8-50ec-80b5-49f6b80d87ab-584df7b8" className="w-layout-layout quick-stack wf-layout-layout">
              <div className="w-layout-cell cell">
                <NavLink to="/" className={({ isActive }) => (isActive ? "link active" : "link")}>
                  About
                </NavLink>
                <NavLink to="/career" className={({ isActive }) => (isActive ? "link active" : "link")}>
                  Career
                </NavLink>
              </div>
              <div className="w-layout-cell cell-2">
                <NavLink to="/projects" className={({ isActive }) => (isActive ? "link active" : "link")}>
                  Projects
                </NavLink>
                <NavLink to="/writings" className={({ isActive }) => (isActive ? "link active" : "link")}>
                  Writings
                </NavLink>
              </div>
              <div className="w-layout-cell cell-3">
                <NavLink to="/contacts" className={({ isActive }) => (isActive ? "link active" : "link")}>
                  Contacts
                </NavLink>
              </div>
            </div>
            <div className="div-line"></div>
            <Routes>
              <Route path="/" element={<About />} />
              <Route path="/career" element={<Career />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/writings" element={<Writings />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/artical/:id" element={<Artical />} />
            </Routes>
            <div className="div-line"></div>
            <div className="div-footer">
              <a href="/" className="link terms">Copyright © 2024 0xSumo</a>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;

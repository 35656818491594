function Career() {
  return (
      <div className="about">
        <h1 className="about-title">Foreword</h1>
        <p className="paragraph">Note : Dates are in DD/MM/YYYY format.<br />‍<br />My goal with this portfolio is to document my journey, not only to inspire those who might come across it but also to reflect on my own progress and the dedication I&#x27;ve put into my career over the years.<br /><br />I start with simple systems, always mindful of where to integrate complex data structures in the future, preferring to evolve my projects iteratively rather than getting bogged down in trying to make everything perfect from the start.<br /><br />Building projects daily fuels my passion as long as I like what I&#x27;m doing. <br />My curiosity and drive to innovate often lead me to juggle multiple projects simultaneously, capturing new inspirations as they come.</p>
        <h3 className="career-time">2021</h3>
        <h4 className="career-time detail date">19/02/2021</h4>
        <p className="paragraph detail text">I was an uni student and learning pure maths. Eventually, COVID struck around those times and I start fucking around with crypto.</p>
        <h4 className="career-time detail date">02/04/2021</h4>
        <p className="paragraph detail text">Embarked on a journey to learn programming from scratch, diving headfirst into Solidity without any prior knowledge, albeit with some exposure to crypto through trading.</p>
        <h4 className="career-time detail date">16/07/2021</h4>
        <p className="paragraph detail text">Focused on building projects using Solidity and JavaScript, including a simple ERC20 launchpad which took me two months to develop. Concurrently, I immersed myself in NFT trading and developed ERC721/ERC1155 smart contracts for clients online. The emergence of Cyberkongz and their $BANANA ERC20 token further fueled my curiosity and understanding of crypto ecosystems.</p>
        <h4 className="career-time detail date">13/12/2021</h4>
        <p className="paragraph detail text">Determined to advance my career in technology, I dedicated significant effort to networking and reaching out to people, actively seeking tech job opportunities. Driven by my passion and obsession with the crypto space, I pursued every avenue to further my involvement.</p>
        <h3 className="career-time yearbelow">2022</h3>
        <h4 className="career-time detail date">17/02/2022</h4>
        <p className="paragraph detail text">Began assisting multiple NFT projects in Japan as a developer and contributor. Engaged in extensive NFT trading, particularly favoring projects that resonated with my personal interests. Aimed to introduce advanced technology to Japan&#x27;s vibrant art and anime culture.</p>
        <h4 className="career-time detail date">09/04/2022</h4>
        <p className="paragraph detail text">Continued crafting smart contracts, fueled by spontaneous ideas and envisioning their potential impact and scalability. Focused on assembling the puzzle pieces of innovative concepts and exploring their potential applications.</p>
        <h4 className="career-time detail date">18/06/2022</h4>
        <p className="paragraph detail text">Presented at the NFT NYC conference in Brooklyn, addressing the practical applications of blockchain technology in real-world scenarios. Emphasized the importance of collaboration between developers and artists, highlighting key considerations for successful partnerships.<br /></p>
        <h4 className="career-time detail date">20/08/2022</h4>
        <p className="paragraph detail text">Inspired by insights gained at NFT NYC, delved deeper into understanding the objectives of blockchain companies and their integration with Web3 technologies. Explored conceptualizing blockchain applications in the physical realm, including the development of NFT minting dApps with integrated Stripe payment systems.<br /></p>
        <h4 className="career-time detail date">01/11/2022</h4>
        <p className="paragraph detail text">Launched OP Sumo Club, my personal NFT project featuring on-chain NFTs with a free minting. Created as a platform to showcase my developments to the NFT community, seeking feedback to refine my understanding of user preferences and interests in the NFT space.<br /></p>
        <h3 className="career-time yearbelow">2023</h3>
        <h4 className="career-time detail date">13/01/2023</h4>
        <p className="paragraph detail text">Started working at PBADAO as a Solidity engineer. Contributed to building optimized contracts for NFTs and provided input on project structure. Also researched new technologies related to web3. Excited about NFTag, a hardware wallet project by the company.<br /></p>
        <h4 className="career-time detail date">15/02/2023</h4>
        <p className="paragraph detail text">Relocated from the countryside of Australia to Sydney in pursuit of better opportunities. Thrilled by the abundance of art galleries in the city. Currently involved in encoding NFC tags with NFTs to enable product authentication and exploring avenues to market these products.<br /></p>
        <h4 className="career-time detail date">09/07/2023</h4>
        <p className="paragraph detail text">Made the decision to move to Japan to fully immerse myself in PBADAO. Recognized the need for personal and professional growth at the age of 20. Believed that Japan would offer more opportunities to collaborate with talented individuals and further develop as a businessman.<br /></p>
        <h3 className="career-time yearbelow">2024~ tbc</h3>
      </div>
  );
}

export default Career;